<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col>
            <b-input-group>
              <b-form-input
                v-model="search"
                placeholder="Personel Ara"
                @keydown.enter="searchData"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  :disabled="search === ''"
                  @click="searchData"
                ><FeatherIcon icon="SearchIcon" /></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="auto">
            <b-button
              to="/hr/employees/add"
              variant="primary"
              block
            >
              <FeatherIcon icon="PlusIcon" />
              Ekle
            </b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
          >
            <template #cell(image)="data">
              <b-avatar
                v-if="data.item.image"
                :src="imageFolder + data.item.image"
              />
              <b-avatar
                v-else
                :text="data.item.name[0]+data.item.surname[0]"
              />
            </template>
            <template #cell(name)="data">
              {{ data.item.name }} {{ data.item.surname }}
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/hr/employees/edit/' + data.item.id"
                variant="outline-primary"
                size="sm"
              >
                Güncelle
              </b-button>
            </template>
          </b-table>
        </b-card-text>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BAvatar, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BAvatar,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      imageFolder: `${this.$store.state.app.baseURL}/media/hr/employees/`,
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'image',
          label: '#',
          thStyle: { width: '90px' },
        },
        {
          key: 'name',
          label: 'Adı Soyadı',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
      dataQuery: {
        select: [
          'com_hr_employees.id AS id',
          'com_hr_employees.title AS title',
          'com_hr_employees.name AS name',
          'com_hr_employees.surname AS surname',
          'com_hr_employees.image AS image',
          'com_hr_employees.image AS image',
          'com_hr_employees.edate AS edate',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['hrEmployees/getHr_employees']
      return data
    },
    dataCount() {
      return this.$store.getters['hrEmployees/getHr_employeesCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    searchData() {
      const query = this.dataQuery
      if (this.search.length > 0) {
        query.or_like = {
          'com_hr_employees.name': this.search,
          'com_hr_employees.surname': this.search,
        }
      } else {
        query.or_like = {}
      }
      this.dataQuery = query
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('hrEmployees/hr_employeesList', this.dataQuery)
    },
  },
}
</script>
